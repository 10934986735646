@import "~core/components/collapsibleItem";

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            .card-header {
                padding: 0;
                border-radius: 6px;
                border: 4px solid $light-gray;
                background-color: $off-white;

                &:first-child {
                    border-radius: 6px;
                }

                &:last-child {
                    border-radius: 6px;
                }

                button {
                    text-align: left;
                    display: block;
                    width: 100%;
                }
            }

            .title,
            .card-title.title {
                padding-right: $card-header-padding-right + $collapsible-header-icon-size + 5px;
                padding-left: $spacer;

                &::after {
                    padding-right: $spacer;
                }
            }

            .content,
            .card-body {
                display: none;
                background-color: $collapsible-body-background;
                color: $collapsible-body-color;
                border-radius: 0;
                padding: $spacer-xl $spacer-xl 0 $spacer-xl;

                &:first-child {
                    border-radius: 0;
                }

                &:last-child {
                    border-radius: 0;
                    border-bottom: none;
                }
            }

            &:not(:last-child) {
                margin-bottom: $spacer-xl;
                border-bottom: none;
                border-radius: 6px;
                &+.collapsible-#{$size} {
                    border-radius: 6px;
                }
            }

            &.aside,
            &.no-borders {
                .title::after {
                    right: 0;
                }
            }

            &.aside .title::after {
                top: $card-aside-header-padding-top;
            }

            &.no-borders .title::after {
                top: $card-header-padding-top;
            }

            &:first-child {
                .card-header,
                .card-title {
                    border-radius: 6px;
                }
            }

            &:last-child {
                .card-title,
                .card-body {
                    border-radius: 0 0 calc(#{$card-border-radius} - 1px) calc(#{$card-border-radius} - 1px);
                }

                &:not(.active):not(.aside):not(.no-borders) {
                    .card-header {
                        border-bottom: 4px solid $light-gray;
                    }
                }
            }

            &.active {
                > .card-header > .title,
                > .content-asset > .title, // footer accordions
                > .title,
                > .card-title.title {
                    background-color: $collapsible-header-background-active;
                    @include icon($collapsible-header-icon-close, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
                }

                > .content,
                > .content-asset > .content,
                > .card-body {
                    display: block;
                    > .content:not(.values) { // prevent from affecting plp refinements
                        display: block;
                    }
                }

                &:last-child {
                    .card-header,
                    .card-title {
                        border-radius: 6px;
                        border-bottom: 4px solid $light-gray;
                    }

                    &:not(.aside):not(.no-borders) {
                        .card-body {
                            border-bottom: 1px solid $collapsible-border-color;
                        }
                    }
                }
            }
        }
    }
}