.hide-gift-certificates-applied {
    display: none;
}

.gift-certificates-applied-total {
    color: $danger;
}

.salesforce-payments-errors {
    font-size: 0.8em;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    border: 1px solid lightblue;
    border-radius: $border-radius;
    background: #fee url(../../images/exclamation.svg) 0.5rem 0.5rem/1rem no-repeat;
}

.salesforce-payments-errors:empty {
    display: none;
}

.salesforce-paymentrequest-element-errors {
    margin-top: 0.75rem;
}

.salesforce-paymentrequest-element[disabled] {
    display: none;
}

.salesforce-paymentrequest-element:empty {
    display: none;
}

.salesforce-paymentrequest-element div:not(:first-child) {
    margin-top: 1em;
    padding: 0;
}

.salesforce-secure-payment {
    margin-left: 1.2rem;
    font-size: 0.8em;
    padding: 0.3rem 0.5rem 0.5rem 1.5rem;
    background: url(../../images/padlock.svg) no-repeat;
}

.salesforce-paymentrequest-element-skeletons {
    display: none;
}

.salesforce-payment-messaging {
    height: auto;
    padding: $spacer 0;
}

.salesforce-payment-messaging:empty {
    display: none;
}

.sfpp-payment-message-afterpay-clearpay-message {
    .AfterpayMessage {
        @include paragraph-font-size(small);
        text-align: left;
        margin: 0 !important; // override for sfpay inline styles
        padding-top: $spacer-sm;
    }
    .AfterpayMessage-logoSvg {
        width: 4.5rem;
        height: auto;
    }
    .AfterpayMessage-learnMore {
        visibility: hidden;
        @include icon($tooltip-icon, before, $tooltip-icon-size, $brand-primary);
        &::before {
            visibility: visible;
            position: absolute;
        }
    }
}
