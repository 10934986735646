@import "~core/components/hero";

.hero-text {
    .hero-body {
        @include media-breakpoint-up(sm) {
            ul {
                columns: 1;
            }
        }
    }
}