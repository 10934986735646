@import "~core/product/grid";

.plp {
    background: $off-white;
    margin-bottom: -100px;
    padding-bottom: 100px;

    &.page-designer {
        .search-results-header {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.grid-header {
    .custom-select {
        border: 1px solid $gray;
        border-radius: 6px;
        background-image:  url('../images/sort-by-arrow.svg');
        padding-left: $spacer;
        @include paragraph-font-size(large);

        &:after {
            position: absolute;
            top: $card-header-padding-top;
            right: $card-header-padding-right;
        }
        
        option {
            font-weight: 300;
        }
    }
}

.grid-site-search {
    position: relative;

    .search-field {
        border: 1px solid $gray;
        border-radius: 6px;
        font-size: 18px;
    }

    .grid-search-button {
        position: absolute;
        right: 12px;
        top: 22%;

        &::before {
            display: inline-block;
            font-size: 18px;
        }
    }
}

.result-count {
    .reset {
        text-decoration: none;
        font-size: 16px;
    }

    span {
        color: $dark-gray;
        @include paragraph-font-size;
    }
}

.filter-bar {
    ul,
    .result-count {
        padding-top: 45px;
        padding-bottom: $spacer-xl;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: $spacer;
        }
    }

    ul {
        padding-left: 0;

        @include media-breakpoint-up(lg) {
            padding-left: $spacer;
        }
    }
}

.refinement-bar {
    background-color: $white;
    height: 100%;
    border-radius: 6px;
    border: 1px solid $gray;

    // overwrite mobile styling that hides refinements so they always show
    position: relative;
    transform: none;
    transition: none;
    width: 100%;
    max-width: 100%;
    padding-bottom: 0;

    .secondary-bar {
        margin-bottom: 0;
        padding: $spacer 0;
    }

    .collapsible-xl.active > .card-header > .title {
        &:after {
            color: $brand-primary;
        }
    }

    .card {
        margin-bottom: 0;

        &.aside {
            border-color: $gray;
            padding: 0 $spacer;
    
            .card-header .card-title {
                @include paragraph-font-size(large);
            }
        }
    }
}

.product-grid.row:not(.no-gutters) > [class*=col-] {
    @include media-breakpoint-up(md) {
        padding-right: 12px;
        padding-left: 12px;
    }
}
