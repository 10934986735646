/*
* ICON SETUP INSTRUCTIONS
* 1) To edit Icomoon fonts, import the /fonts/icomoon/selection.json file
*    at this URL: https://icomoon.io/app/#/select
* 2) After making changes and generating the font, replace everything in /fonts/icomoon
*    with all the files and folders downloaded from Icomoon
* 3) Copy the font files from /fonts/icomoon/fonts to /static/fonts/
* 4) Replace the @font-face rule in this file with the one from fonts/icomoon/style.scss. Or, if
*    you have customizations, just update the URL hashes to make sure the old files aren't cached
* 5) Replace the variables in this file with the variables from fonts/icomoon/variables.scss
* 6) Replace the classes in this file with the classes from fonts/icomoon/style.scss
* 7) Check the Icons section of the Style Guide to make sure any new/updated icons are
*    rendering properly. New icons should get added automatically by reading the contents
*    of /fonts/icomoon/selection.json (see Styleguide-Show)
*/

// Not copied from any icon set, do not overwrite
$icon-content-blank: "";
// Not copied from any icon set, do not overwrite

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?h99svi');
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?h99svi#iefix') format('embedded-opentype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?h99svi') format('truetype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?h99svi') format('woff'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?h99svi##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"]:not(.btn),
[class*=" icon-"]:not(.btn) {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*
* Copy line 4 down from fonts/icomoon/variables.scss
*/
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-gamification: unquote('"\\e965"');
$icon-diversity: unquote('"\\e966"');
$icon-combo-bundle: unquote('"\\e967"');
$icon-cloud: unquote('"\\e968"');
$icon-admin: unquote('"\\e940"');
$icon-calendar: unquote('"\\e941"');
$icon-categories: unquote('"\\e942"');
$icon-certificate: unquote('"\\e943"');
$icon-communication: unquote('"\\e944"');
$icon-customer-service: unquote('"\\e945"');
$icon-icon-download: unquote('"\\e946"');
$icon-elearning: unquote('"\\e947"');
$icon-etips: unquote('"\\e948"');
$icon-excel: unquote('"\\e949"');
$icon-faq: unquote('"\\e94a"');
$icon-finance: unquote('"\\e94b"');
$icon-government: unquote('"\\e94c"');
$icon-hr: unquote('"\\e94d"');
$icon-video-quiz: unquote('"\\e94e"');
$icon-integration: unquote('"\\e94f"');
$icon-it: unquote('"\\e950"');
$icon-learning: unquote('"\\e951"');
$icon-icon-livechat: unquote('"\\e952"');
$icon-icon-management: unquote('"\\e953"');
$icon-marketing: unquote('"\\e954"');
$icon-microlearning: unquote('"\\e955"');
$icon-mobile-friendly: unquote('"\\e956"');
$icon-msoffice: unquote('"\\e957"');
$icon-osha: unquote('"\\e958"');
$icon-personal-development: unquote('"\\e959"');
$icon-project-management: unquote('"\\e95a"');
$icon-quickbooks: unquote('"\\e95b"');
$icon-sales: unquote('"\\e95c"');
$icon-skillbuilder: unquote('"\\e95d"');
$icon-software: unquote('"\\e95e"');
$icon-speedreading: unquote('"\\e95f"');
$icon-spotlight: unquote('"\\e960"');
$icon-time-management: unquote('"\\e961"');
$icon-video: unquote('"\\e962"');
$icon-webinar: unquote('"\\e963"');
$icon-write: unquote('"\\e964"');
$icon-onsite: unquote('"\\e902"');
$icon-tiktok: unquote('"\\e903"');
$icon-cart-full: unquote('"\\e906"');
$icon-award: unquote('"\\e91e"');
$icon-download: unquote('"\\e91f"');
$icon-in-person: unquote('"\\e920"');
$icon-live-virtual: unquote('"\\e926"');
$icon-time: unquote('"\\e92a"');
$icon-upskill: unquote('"\\e92b"');
$icon-pryorplus: unquote('"\\e93e"');
$icon-trust: unquote('"\\e93f"');
$icon-menu: unquote('"\\e929"');
$icon-images: unquote('"\\e905"');
$icon-alert: unquote('"\\e904"');
$icon-account: unquote('"\\e900"');
$icon-add-to-cart: unquote('"\\e901"');
$icon-arrow-down: unquote('"\\e907"');
$icon-arrow-left-thin: unquote('"\\e908"');
$icon-arrow-left: unquote('"\\e909"');
$icon-arrow-right-thin: unquote('"\\e90a"');
$icon-arrow-right: unquote('"\\e90b"');
$icon-arrow-top: unquote('"\\e90c"');
$icon-arrow-up: unquote('"\\e90d"');
$icon-call: unquote('"\\e90e"');
$icon-cart: unquote('"\\e90f"');
$icon-checkmark: unquote('"\\e910"');
$icon-close-small: unquote('"\\e911"');
$icon-close: unquote('"\\e912"');
$icon-collapse: unquote('"\\e913"');
$icon-edit: unquote('"\\e914"');
$icon-email: unquote('"\\e915"');
$icon-expand: unquote('"\\e916"');
$icon-facebook: unquote('"\\e917"');
$icon-favorite-selected: unquote('"\\e918"');
$icon-favorite: unquote('"\\e919"');
$icon-film: unquote('"\\e91a"');
$icon-google: unquote('"\\e91b"');
$icon-guarantee: unquote('"\\e91c"');
$icon-image: unquote('"\\e91d"');
$icon-info: unquote('"\\e921"');
$icon-instagram: unquote('"\\e922"');
$icon-link: unquote('"\\e923"');
$icon-linkedin: unquote('"\\e924"');
$icon-livechat: unquote('"\\e925"');
$icon-location: unquote('"\\e927"');
$icon-lock: unquote('"\\e928"');
$icon-minus: unquote('"\\e92c"');
$icon-offsite: unquote('"\\e92d"');
$icon-pause: unquote('"\\e92e"');
$icon-pinterest: unquote('"\\e92f"');
$icon-play-hollow: unquote('"\\e930"');
$icon-plus: unquote('"\\e931"');
$icon-print: unquote('"\\e932"');
$icon-question: unquote('"\\e933"');
$icon-remove: unquote('"\\e934"');
$icon-search: unquote('"\\e935"');
$icon-size: unquote('"\\e936"');
$icon-star-empty: unquote('"\\e937"');
$icon-star-half: unquote('"\\e938"');
$icon-star: unquote('"\\e939"');
$icon-twitter: unquote('"\\e93a"');
$icon-youtube: unquote('"\\e93b"');
$icon-zoom-out: unquote('"\\e93c"');
$icon-zoom: unquote('"\\e93d"');
$icon-globe: unquote('"\\e9ca"');

/*
* Copy line 30 down from from fonts/icomoon/style.scss
*/
.icon-gamification {
  &:before {
    content: $icon-gamification;
  }
}
.icon-diversity {
  &:before {
    content: $icon-diversity;
  }
}
.icon-combo-bundle {
  &:before {
    content: $icon-combo-bundle;
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud;
  }
}
.icon-admin {
  &:before {
    content: $icon-admin;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-categories {
  &:before {
    content: $icon-categories;
  }
}
.icon-certificate {
  &:before {
    content: $icon-certificate;
  }
}
.icon-communication {
  &:before {
    content: $icon-communication;
  }
}
.icon-customer-service {
  &:before {
    content: $icon-customer-service;
  }
}
.icon-icon-download {
  &:before {
    content: $icon-icon-download;
  }
}
.icon-elearning {
  &:before {
    content: $icon-elearning;
  }
}
.icon-etips {
  &:before {
    content: $icon-etips;
  }
}
.icon-excel {
  &:before {
    content: $icon-excel;
  }
}
.icon-faq {
  &:before {
    content: $icon-faq;
  }
}
.icon-finance {
  &:before {
    content: $icon-finance;
  }
}
.icon-government {
  &:before {
    content: $icon-government;
  }
}
.icon-hr {
  &:before {
    content: $icon-hr;
  }
}
.icon-video-quiz {
  &:before {
    content: $icon-video-quiz;
  }
}
.icon-integration {
  &:before {
    content: $icon-integration;
  }
}
.icon-it {
  &:before {
    content: $icon-it;
  }
}
.icon-learning {
  &:before {
    content: $icon-learning;
  }
}
.icon-livechat {
  &:before {
    content: $icon-icon-livechat;
  }
}
.icon-management {
  &:before {
    content: $icon-icon-management;
  }
}
.icon-marketing {
  &:before {
    content: $icon-marketing;
  }
}
.icon-microlearning {
  &:before {
    content: $icon-microlearning;
  }
}
.icon-mobile-friendly {
  &:before {
    content: $icon-mobile-friendly;
  }
}
.icon-msoffice {
  &:before {
    content: $icon-msoffice;
  }
}
.icon-osha {
  &:before {
    content: $icon-osha;
  }
}
.icon-personal-development {
  &:before {
    content: $icon-personal-development;
  }
}
.icon-project-management {
  &:before {
    content: $icon-project-management;
  }
}
.icon-quickbooks {
  &:before {
    content: $icon-quickbooks;
  }
}
.icon-sales {
  &:before {
    content: $icon-sales;
  }
}
.icon-skillbuilder {
  &:before {
    content: $icon-skillbuilder;
  }
}
.icon-software {
  &:before {
    content: $icon-software;
  }
}
.icon-speedreading {
  &:before {
    content: $icon-speedreading;
  }
}
.icon-spotlight {
  &:before {
    content: $icon-spotlight;
  }
}
.icon-time-management {
  &:before {
    content: $icon-time-management;
  }
}
.icon-video {
  &:before {
    content: $icon-video;
  }
}
.icon-webinar {
  &:before {
    content: $icon-webinar;
  }
}
.icon-write {
  &:before {
    content: $icon-write;
  }
}
.icon-onsite {
  &:before {
    content: $icon-onsite;
  }
}
.icon-tiktok {
  &:before {
    content: $icon-tiktok;
  }
}
.icon-cart-full {
  &:before {
    content: $icon-cart-full;
  }
}
.icon-award {
  &:before {
    content: $icon-award;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-in-person {
  &:before {
    content: $icon-in-person;
  }
}
.icon-live-virtual,
.icon-online {
  &:before {
    content: $icon-live-virtual;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-upskill {
  &:before {
    content: $icon-upskill;
  }
}
.icon-pryorplus {
  &:before {
    content: $icon-pryorplus;
  }
}
.icon-trust {
  &:before {
    content: $icon-trust;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-images {
  &:before {
    content: $icon-images;
  }
}
.icon-alert {
  &:before {
    content: $icon-alert;
  }
}
.icon-account {
  &:before {
    content: $icon-account;
  }
}
.icon-add-to-cart {
  &:before {
    content: $icon-add-to-cart;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left-thin {
  &:before {
    content: $icon-arrow-left-thin;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right-thin {
  &:before {
    content: $icon-arrow-right-thin;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-top {
  &:before {
    content: $icon-arrow-top;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-call {
  &:before {
    content: $icon-call;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.icon-close-small {
  &:before {
    content: $icon-close-small;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-collapse {
  &:before {
    content: $icon-collapse;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-expand {
  &:before {
    content: $icon-expand;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-favorite-selected {
  &:before {
    content: $icon-favorite-selected;
  }
}
.icon-favorite {
  &:before {
    content: $icon-favorite;
  }
}
.icon-film {
  &:before {
    content: $icon-film;
  }
}
.icon-google {
  &:before {
    content: $icon-google;
  }
}
.icon-guarantee {
  &:before {
    content: $icon-guarantee;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-livechat {
  &:before {
    content: $icon-livechat;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-offsite {
  &:before {
    content: $icon-offsite;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-play-hollow {
  &:before {
    content: $icon-play-hollow;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-remove {
  &:before {
    content: $icon-remove;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-size {
  &:before {
    content: $icon-size;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out;
  }
}
.icon-zoom {
  &:before {
    content: $icon-zoom;
  }
}
.icon-globe {
  &:before {
    content: $icon-globe;
  }
}
