@import "~core/components/footer";

.footer-customer-support {
    background-color: $footer-customer-support-background;
    padding: $spacer 0;
    color: $white;
    margin-bottom: 38px;
}

.footer-customer-support-number {
    color: $white;
    padding-left: $spacer-sm;

    @include media-breakpoint-up(md) {
        text-decoration: none;
        font-weight: 600;
    }

    &:hover {
        color: $white;
    }
}

.footer-customer-support-text {
    @include media-breakpoint-down(lg){
        font-size: 16px;
        font-weight: 400;
    }
}

footer {
    .social-links {
        a {
            color: $blue-gray;
        }
    }

    .collapsible-sm.active  > .content-asset > .title {
        background-color: $collapsible-header-background-footer;
        &::after {
            color: $footer-title-font-color;
            font-size: 20px;
        }
    }

    .footer-item {
        .title {
            border-bottom: 1px solid $footer-title-border-color;
            padding: 20px 40px 20px 0;
            color: $white;
            background-color: $collapsible-header-background-footer;

            @include media-breakpoint-up(md) {
                border-bottom: 0;
                padding: 0;
            }

            &::after {
                color: $footer-title-font-color;
                font-size: $footer-title-font-size;
                right: 0;
            }
        }

        .content li a {
            line-height: 24px;
        }
    }
}

.copyright {
    font-weight: 300;
    order: 2;
    @include media-breakpoint-up(md) {
        order: 1;
    }
}

.postscript {
    padding-top: 64px;
    padding-bottom: 25px;
    order: 1;
    @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-bottom: 0;
        order: 2;
    }
}

.email-signup-form {
    label {
        color: $white;
        font-size: 12px;
        font-weight: 300;
        line-height: 21px;

        &::after {
            content: '*';
            color: $orange;
        }
    }

    .email-input {
        input {
            background-color: $white;
            color: $black;
            margin-bottom: $spacer;
        }
    }

    .btn[class*=btn-]:not(.title) {
        padding-right: 45px;
        &::after {
            content: '';
        }
        &:hover {
            background-color: $brand-tertiary;
        }
    }
}
