@import "~core/helperClasses";

// Create button classes, e.g. .btn-primary
.btn {
    @include button;
    &-primary {
        @include button(primary);
    }
    &-secondary,
    &-outline-primary {
        @include button(secondary);
    }
    &-tertiary {
        @include button(tertiary);
    }
    &-gradient {
        @include button(gradient);
    }
    &-sm {
        @include button(null, null, null, null, small);
        &.btn-primary {
            @include button(primary, null, null, null, small);
        }
        &.btn-secondary,
        &.btn-outline-primary {
            @include button(secondary, null, null, null, small);
        }
        &.btn-tertiary {
            @include button(tertiary, null, null, null, small);
        }
    }
    &-lg {
        @include button(null, null, null, null, large);
        &.btn-primary {
            @include button(primary, null, null, null, large);
        }
        &.btn-secondary,
        &.btn-outline-primary {
            @include button(secondary, null, null, null, large);
        }
        &.btn-tertiary {
            @include button(tertiary, null, null, null, large);
        }
    }
    &-block {
        @include full-width;
    }
}

.background--blue-gradient {
    background: rgb(0,113,188);
    background: linear-gradient(96deg, rgba(0,113,188,1) 0%, rgba(1,112,187,1) 50%, rgba(40,77,139,1) 80%);
}

.background--pryor-plus-gradient {
    background: rgb(225,83,217);
    background: linear-gradient(98deg, rgba(225,83,217,1) 0%, rgba(36,43,79,1) 33%, rgba(8,94,107,1) 58%, rgba(47,93,171,1) 72%, rgba(203,117,88,1) 100%);
}

.border-radius--6 {
    border-radius: 6px;
}

.hero-button--btn-block {
    .hero-button {
        width: 100%;
        display: block;
    }
}