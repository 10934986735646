@import "~core/checkout/checkout";

.data-checkout-stage {
    .gift-message-block {
        display: none;
    }

    // PL-244 always show shipping form whether or not its digital
    &[data-checkout-stage] {
        &.hide-shipping {
            .shipping-summary,
            .shipping-section {
                display: block;
            }
        }
    }
}

.data-checkout-stage,
.receipt,
.account-page {
    .order-product-summary {
        &.hide-shipping-method {
            .js-shipping-line-items {
                display: none; // js injected shipping lines per item
            }
        }
    }
}