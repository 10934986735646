@import "~core/components/productTiles";
.product {
    height: 100%;
    padding-bottom: $spacer-xl;
}

.product-tile {
    background-color: $white;
    padding: 30px 15px;
    border-radius: 6px;
    box-shadow: -2px 2px 7.1px 2px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    height: 100%;

    .product-duration {
        display: flex;
        align-items: center;
        @include icon($icon-time, before, 21px, $brand-primary);
        &::before {
            padding-right: $spacer;
        }
    }

    .product-credits-icon {
        &::before {
            padding-right: $spacer;
            font-size: 21px;
            color: $brand-primary;
        }
    }

    .product-credits {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        
        .product-credit:not(:last-child) {
            &::after {
                content: '|';
                margin: 0 $spacer-sm;
            }
        }
    }

    .quick-action-btns {
        display: none; // quickview is not utilized per designs
    }

    .event-type-list {
        padding-top: 9px;

        .event-type-item {
            @include paragraph-font-size;
            line-height: 18.2px;
            padding-bottom: $spacer;
            display: flex;
            align-items: center;

            .event-type-icon::before {
                font-size: 21px;
                padding-right: $spacer;
                color: $brand-primary;
            }
        }
    }

    .tile-body {
        flex: 1 0 auto;
        padding-bottom: 0;
    }

    .view-details-btn {
        display: block;
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: auto;
        }
     }

     .product-tile-dates {
        justify-content: space-between;
        width: 100%;
        .card {
            flex-basis: calc(50% - 5px);
            padding: 10px 5px;
            margin-bottom: $spacer;

            & > .h6{
                font-weight: 700;
                margin-bottom: 10px;
            }

            a {
                font-size: 12px;
                font-weight: 700;
            }
        }
        ul {
            list-style: none;
            padding-left: 0;

            li {
                padding-left: 0;
                margin-bottom: 5px;
            }
        }
     }
}